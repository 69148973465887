<template>
	<div id="nuvei-cash-out">
		<div class="content-container">
			<div v-if="allowCashOutLATAM || allowCashOutSTPmex" id="payment-type-section">
				<label for="offer-payment-type" class="center">{{ languageStrings.paymentType }}:</label>
				<select
					id="offer-payment-type"
					class="curved-border"
					v-model="paymentType"
					:placeholder="languageStrings.selectPaymentType"
				>
					<option v-if="allowCashOutLATAM">LATAM</option>
					<option v-if="allowCashOutSTPmex">STPmex</option>
				</select>
			</div>
			<div class="input-section" v-if="selectedCasino?.id">
				<label for="toPlayerCashCOC">{{ languageStrings.webPayCashOutCOC }}</label>
				<span v-if="webPayOptions?.nuvei?.cashOutMinimumCOC"
					>Min:
					{{
						systemCurrencyTool.formatCurrency(
							webPayOptions?.nuvei?.cashOutMinimumCOC,
							systemCurrencyTool.displayType.minorWholeOrFull
						)
					}}</span
				>
				<span v-if="webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC"
					>Max:
					{{
						systemCurrencyTool.formatCurrency(
							webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC,
							systemCurrencyTool.displayType.minorWholeOrFull
						)
					}}</span
				>
				<span>
					<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
						systemCurrencyTool.currencyInfo.symbol
					}}</span>
					<input
						type="number"
						id="toPlayerCashCOC"
						name="displayCOC"
						v-model="toPlayerCashCOC"
						:min="webPayOptions?.nuvei?.cashOutMinimumCOC"
						:max="webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC"
						:placeholder="languageStrings.fromPlayerCash"
						autocomplete="off"
					/>
					<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
						systemCurrencyTool.currencyInfo.symbol
					}}</span>
				</span>
				<span>
					<span class="currancy-symbol">{{ systemCurrencyTool.currencyInfo.symbol }}</span>
					<input
						type="range"
						name="displayCOC"
						:min="0"
						:max="maxCashout"
						v-model="toPlayerCashCOC"
						autocomplete="off"
					/>
					<span class="currancy-symbol"
						>{{ systemCurrencyTool.currencyInfo.symbol }}{{ systemCurrencyTool.currencyInfo.symbol
						}}{{ systemCurrencyTool.currencyInfo.symbol }}</span
					>
				</span>
			</div>
			<div>
				<div class="row">
					<div class="column">
						<button
							class="btn add-in-btn encore-price-button"
							@click="addMoneyToInput(10)"
							:disabled="
								restrictedNeedsMoreInfo &&
								toPlayerCashCOC + 10 > webPayOptions.taxAmlRestrictions.buyInThresholdCOC
							"
						>
							<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront"
								>{{ systemCurrencyTool.currencyInfo.symbol }}10</span
							>

							<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
								systemCurrencyTool.currencyInfo.symbol
							}}</span>
						</button>
					</div>
					<div class="column">
						<button
							class="btn add-in-btn encore-price-button"
							@click="addMoneyToInput(25)"
							:disabled="
								restrictedNeedsMoreInfo &&
								toPlayerCashCOC + 25 > webPayOptions.taxAmlRestrictions.buyInThresholdCOC
							"
						>
							<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront"
								>{{ systemCurrencyTool.currencyInfo.symbol }}25</span
							>

							<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
								systemCurrencyTool.currencyInfo.symbol
							}}</span>
						</button>
					</div>
				</div>
				<div class="row">
					<div class="column">
						<button
							class="btn add-in-btn encore-price-button"
							@click="addMoneyToInput(50)"
							:disabled="
								restrictedNeedsMoreInfo &&
								toPlayerCashCOC + 50 > webPayOptions.taxAmlRestrictions.buyInThresholdCOC
							"
						>
							<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront"
								>{{ systemCurrencyTool.currencyInfo.symbol }}50</span
							>

							<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
								systemCurrencyTool.currencyInfo.symbol
							}}</span>
						</button>
					</div>
					<div class="column">
						<button
							class="btn add-in-btn encore-price-button"
							@click="addMoneyToInput(100)"
							:disabled="
								restrictedNeedsMoreInfo &&
								toPlayerCashCOC + 100 > webPayOptions.taxAmlRestrictions.buyInThresholdCOC
							"
						>
							<span v-if="systemCurrencyTool.currencyInfo.doesSymbolGoInFront"
								>{{ systemCurrencyTool.currencyInfo.symbol }}100</span
							>

							<span v-if="!systemCurrencyTool.currencyInfo.doesSymbolGoInFront">{{
								systemCurrencyTool.currencyInfo.symbol
							}}</span>
						</button>
					</div>
				</div>
			</div>
			<div class="button-container">
				<button class="btn buy-in-btn curved-border" id="commit-cash-out" @click="order()">
					{{ languageStrings.webPayCashOut }}
				</button>
			</div>
			<div id="nuvei-container" :class="nuveiShow ? 'show' : ''">
				<button class="btn" @click="cancelNuvei()">{{ buttonText }}</button>
				<div id="nuvei-iframe-container" v-if="safeChargeApiResult?.redirectUrl">
					<iframe id="nuvei-iframe" :src="safeChargeApiResult.redirectUrl" @load="checkIframeURL()"></iframe>
				</div>
			</div>
			<dialog id="nuvei-dialog" @click="closeNuveiDialog()">
				<div>
					<h2>{{ languageStrings.transactionResult }}</h2>
					<p
						v-if="
							rabbitsfootMessage?.status !== 'RefusedByProvider' && rabbitsfootMessage?.result !== 'ERROR'
						"
					>
						{{ checkoutStatus }}<br />
						{{
							systemCurrencyTool.formatCurrency(
								toPlayerCashCOC,
								systemCurrencyTool.displayType.minorOrFull
							)
						}}
					</p>
					<div v-else>
						<p>{{ languageStrings.refusedByProvider }}</p>
						<p v-if="rabbitsfootMessage?.statusAdditionalInfo">
							{{ languageStrings.additionalInfo }}: {{ rabbitsfootMessage.statusAdditionalInfo }}
						</p>
					</div>
					<div id="btn-container">
						<router-link class="btn" :title="languageStrings.viewBalances" to="/">{{
							languageStrings.viewBalances
						}}</router-link>
					</div>
				</div>
			</dialog>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "NuveiCashOut",
	inheritAttrs: false,
	props: {
		playerState: Object,
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		systemSettings: Object,
		selectedCasino: Object,
		webPayOptions: Object,
	},
	components: {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			cashOutCurrency: {},
			inGameCurrencyInfo: {},
			nuveiResult: {},
			nuveiDialog: null,
			casinoId: null,
			nuveiShow: false,
			rabbitsfootMessage: null,
			toPlayerCashCOC: 0,
			paymentType: null,
			allowCashOutLATAM: this.webPayOptions?.nuvei?.allowCashOutLATAM,
			allowCashOutSTPmex: this.webPayOptions?.nuvei?.allowCashOutSTPmex,
			safeChargeApiResult: null,
			buttonText: "",
			transactionStatus: null,
			transactions: null,
			checkoutStatus: "",
			morePersonalInfoNeeded: this.webPayOptions?.taxAmlRestrictions?.morePersonalInfoNeeded,
			idCheckNeeded: this.webPayOptions?.taxAmlRestrictions?.idCheckNeeded,
			restrictedNeedsMoreInfo:
				this.webPayOptions?.taxAmlRestrictions?.idCheckNeeded ||
				this.webPayOptions?.taxAmlRestrictions?.morePersonalInfoNeeded,
			maxCashout: this.webPayOptions.nuvei.cashOutMaximumCOC,
		};
	},
	watch: {
		toPlayerCashCOC() {
			this.toPlayerCashCOC = Number(this.toPlayerCashCOC);
		},
	},
	created() {
		this.maxCashout = this.restrictedNeedsMoreInfo
			? this.webPayOptions.taxAmlRestrictions.cashOutThresholdCOC
			: this.webPayOptions.nuvei.cashOutMaximumCOC;

		this.eventBus.on("showNuveiWidgit", () => {
			this.nuveiShow = true;
		});
		this.eventBus.on("handelNuveiError", (payload) => {
			this.rabbitsfootMessage = payload;
			this.nuveiShow = false;
			this.showNuveiDialog();
		});
		this.eventBus.on("nuveiCheckoutResult", (payload) => {
			this.nuveiResult = payload;
			this.nuveiShow = false;
			this.showNuveiDialog();
		});
		onBeforeUnmount(() => {
			window.document.removeEventListener("transactionComplete", this.handlPayCompleteEvent, false);
			this.eventBus.off("showNuveiWidgit");
			this.eventBus.off("handelNuveiError");
			this.eventBus.off("nuveiCheckoutResult");
		});
	},
	mounted() {
		this.nuveiDialog = document.getElementById("nuvei-dialog");
		window.document.addEventListener("transactionComplete", this.handlPayCompleteEvent, false);
	},
	methods: {
		addMoneyToInput(amount) {
			let toPlayer = Number(this.toPlayerCashCOC) + amount;
			if (toPlayer > this.maxCashout) return;
			this.toPlayerCashCOC = toPlayer;
		},
		checkIframeURL() {},
		handlPayCompleteEvent(e) {
			this.checkoutStatus = e.detail;
			this.showNuveiDialog();
			this.eventBus.emit("updateBalance", this.playerState);
			this.eventBus.emit("casinoSelected", this.selectedCasino);
		},
		async order() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.loadingWebPayCashout;
			this.buttonText = this.languageStrings.cancelTransaction;

			let hasErrors = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				hasErrors = true;
			}

			if (!this.toPlayerCashCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterACashOutAmount;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (isNaN(this.toPlayerCashCOC)) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustEnterNumberOnly;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (!this.paymentType && (this.allowCashOutLATAM || this.allowCashOutSTPmex)) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustSelectAPaymentType;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			let maxCOC =
				this.webPayOptions?.taxAmlRestrictions?.idCheckNeeded ||
				this.webPayOptions?.taxAmlRestrictions?.morePersonalInfoNeeded
					? this.webPayOptions?.taxAmlRestrictions?.cashOutThresholdCOC
					: this.webPayOptions?.nuvei?.cashOutMaximumCOC;

			if (this.toPlayerCashCOC < this.webPayOptions?.nuvei?.cashOutMinimumCOC || this.toPlayerCashCOC > maxCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = `${this.languageErrorStrings.transactionAmountAllowedBetween} ${this.webPayOptions.nuvei.cashOutMinimumCOC} ${this.languageStrings.and} ${maxCOC}`;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (
				this.systemCurrencyTool.toAUFromCurrency(this.toPlayerCashCOC) > this.selectedCasino.balance.regularAU
			) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.notEnoughFundsInPlayerAccount;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.toPlayerCashCOC < this.webPayOptions.nuvei.cashOutMinimumCOC) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.mustMeetMinimumCashOutValue;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (hasErrors === true) return;

			let body = {
				casinoId: this.webPayOptions.casino.id,
				toPlayerCashCOC: this.toPlayerCashCOC,
				provider: "Nuvei",
			};

			let requestUrl = new URL("/api/v1/webpay/cashout/initiate", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				this.transaction = await response.json();

				if (this.transaction?.status === "EmailNotVerified") {
					console.error(this.transaction?.status);
					this.status.ok = false;
					this.status.message =
						this.languageErrorStrings.emailNotVerifiedPleaseGoToYourUserProfileAndVerifyYourEmail;
					this.eventBus.emit("updateStatus", this.status);
					return;
				}

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(this.transaction.status, this.languageErrorStrings);

				if (this.status.message === "Success") {
					this.status.ok = true;
					this.status.message = this.languageErrorStrings.webPayInitiated;
					this.eventBus.emit("updateStatus", this.status);
				}

				this.performNuveiCashoutCheckout();
			} catch (e) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.webPayInitiateFailed;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		performNuveiCashoutCheckout() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.waitingForAccountCaptureResponseFromNuvei;

			// Initialize the Nuvei/SafeCharge API.
			let safeChargeApi = SafeCharge({
				env: this.webPayOptions.nuvei.environment,
				sessionToken: this.transaction.sessionId,
				merchantId: this.webPayOptions.nuvei.merchantId,
				merchantSiteId: this.webPayOptions.nuvei.merchantSiteId,
			});

			let paymentTypeString = "";
			if (this.paymentType === "LATAM") paymentTypeString = "apmgw_LATAM_Payouts";
			if (this.paymentType === "STPmex") paymentTypeString = "apmgw_MX_Payouts";

			// Tell Nuvei that we want a URL to an "account capture" form.
			const captureParams = {
				sessionToken: this.transaction.sessionId,
				merchantId: this.webPayOptions.nuvei.merchantId,
				merchantSiteId: this.webPayOptions.nuvei.merchantSiteId,
				paymentMethod: paymentTypeString,
				userTokenId: this.transaction.transaction.userId,
				currencyCode: this.webPayOptions.cashOutCurrency.iso4217Code,
				countryCode: this.webPayOptions.nuvei.merchantTwoLetterCountryCode,
				languageCode: this.systemCurrencyTool.cultureCode.split("-")[0],
				urlDetails: {
					successUrl: `${window.parent.location.href}/cashout-finished.html?ppp_status=OK`,
					failureUrl: `${window.parent.location.href}/cashout-finished.html?ppp_status=FAIL`,
					pendingUrl: `${window.parent.location.href}/cashout-finished.html?ppp_status=PENDING`,
					notificationUrl: `${this.rabbitsfootHostUrl}/api/v1/webpay/callback/nuvei/capture/${this.transaction.transaction.id}`,
				},
			};
			safeChargeApi.accountCapture(captureParams, this.handleCaptureResult);
		},
		async handleCaptureResult(result) {
			if (result.status === "ERROR") {
				this.status.ok = false;
				this.status.message = result.status;
				this.eventBus.emit("updateStatus", this.status);
				return;
			}

			if (result.status !== "SUCCESS") {
				this.busyText = result.status;
				this.cancelRfTransaction();
				return;
			}
			this.buttonText = this.languageStrings.closeWebPayWindow;
			this.busyText = "";
			this.nuveiShow = true;
			this.safeChargeApiResult = result;
		},
		async cancelRfTransaction() {
			this.busyText = this.languageStrings.cancellingTransaction;

			// Tell the RF server that the transaction isn't going to happen.  (We might want to return them to
			// the buy-in offer stage so they can start over, though.)
			let body = {
				webTranId: this.transaction.id,
				newStatus: "Cancelled",
			};

			let requestUrl = new URL("/api/v1/webpay/transaction/update", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let respone = await fetch(`api/v1/webpay/transaction/update`);
				this.transactionStatus = response;

				this.busyText = "";
			} catch (e) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.webPayInitiateFailed;
				this.eventBus.emit("updateStatus", this.status);
				console.error(e);
			}
		},
		cancelNuvei() {
			this.closeNuveiDialog();
		},
		showNuveiDialog() {
			this.nuveiDialog.showModal();
		},
		closeNuveiDialog() {
			this.toPlayerCashCOC = null;
			this.safeChargeApiResult = null;
			this.nuveiShow = null;
			this.nuveiDialog.close();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	text-align: center;
}

.input-section {
	display: grid;
	flex-direction: row;
	align-items: center;
	/* width: 200px; */
	margin-top: 25px;
	margin-left: auto;
	margin-right: auto;
	justify-content: center;
	justify-items: center;
}

.input-section > span {
	display: flex;
	align-items: center;
}

#nuvei-container {
	display: none;
}

#nuvei-container.show {
	display: grid;
	align-items: center;
	position: fixed;
	top: 100px;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	height: calc(100vh - 100px);
	width: 100vw;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
}

#nuvei-dialog {
	width: 80%;
	margin: auto;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
	box-sizing: border-box;
	text-align: center;
}

::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

#btn-container a {
	text-decoration: none;
}

#btn-container * {
	margin: 15px;
}

select#offer-payment-type {
	width: 65%;
	height: 40px;
	text-align: center;
	/* margin-left: 6vw; */
	color: #5d3fd3;
	font-weight: bolder;
}

#payment-type-section {
	display: grid;
	flex-direction: row;
	align-items: center;
	/* margin-top: 2vh; */
	margin-left: auto;
	margin-right: auto;
	width: 287px;
	justify-items: center;
}

#balances {
	background: white;
	display: flex;
	align-items: center;
	/* text-align: center; */
	color: #5d3fd3;
	border-radius: 25px;
	width: 65%;
	margin-right: auto;
	margin-left: auto;
	font-size: 17px;
	font-weight: bolder;
	height: 5vh;
	margin-top: 3vh;
}

#toPlayerCashCOC {
	border-radius: 25px;
	width: 100%;
	height: 40px;
	color: #5d3fd3;
	text-align: center;
	/* margin-right: 15px; */
	min-width: 200px;
}

button.btn:disabled {
	background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
	color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
	border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}

button.btn:disabled {
	background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
	color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
	border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
}

.row {
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.add-in-btn {
	margin: 10px;
}

.button-container {
	background: white;
	border-radius: 15px;
	width: 190px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 35px;
}

.buy-in-btn {
	/* background: linear-gradient(to right, blue, purple, #fa5f55); */
	/* -webkit-text-fill-color: transparent; */
	background-clip: text;
	margin: auto;
	width: 190px;
	/* height: 4.5vh; */
	font-weight: bolder;
	overflow-wrap: break-word;
	/* margin-top: 3vh; */
}

/* .button-container:hover {
	width: 22.5vw;
	padding-left: 6px;
} */

.current-balance {
	margin: 4px auto;
}

iframe {
	margin: auto;
	width: 90%;
	height: 400px;
	border-radius: 12px;
}

@media (min-width: 768px) {
	#cash-out {
		width: 80%;
		margin-left: 20%;
		position: absolute;
	}

	#nuvei-dialog {
		width: 50%;
	}
	iframe {
		margin: auto;
		width: 50%;
	}
}

@media (min-width: 1629px) {
	.playerCashCOC {
		margin-left: 5.5vw;
	}
}

@media (max-width: 767px) {
	.button-container {
		background: white;
		border-radius: 15px;
		width: 139px;
		margin-left: auto;
		margin-right: auto;
		/* margin-top: 7.5vh; */
	}

	/* .button-container:hover {
		width: 36vw;
	} */

	.buy-in-btn {
		/* background: linear-gradient(to right, blue, purple, #fa5f55);
		-webkit-text-fill-color: transparent; */
		background-clip: text;
		margin: auto;
		border-radius: 15px !important;
		width: 139px;
		/* height: 4.5vh; */
		font-weight: bolder;
		overflow-wrap: break-word;
	}

	/* .content-container {
		margin-top: 3.5vh;
	} */

	#balances {
		width: 90%;
	}

	#toPlayerCashCOC {
		border-radius: 25px;
		width: 90%;
		/* height: 4.6vh; */
		color: #5d3fd3;
		text-align: center;
		margin-right: 0;
	}

	#payment-type-section {
		width: 70%;
		/* margin-left: auto;
		margin-right: 7vw; */
	}

	#payment-type-label {
		margin-left: 9vw;
	}

	select#offer-payment-type {
		width: 120px;
		height: 35px;
		text-align: center;
		margin-left: 10px;
		color: #5d3fd3;
		font-weight: bolder;
	}

	.input-section {
		width: 200px;
		display: grid;
		justify-items: center;
	}

	#payment-type-section {
		display: grid;
		justify-items: center;
	}

	/* @supports (-webkit-touch-callout: none) {
		.input-section {
			margin-top: 0vh !important;
		}

		.button-container {
			margin-top: 30vh !important;
		}
	} */
}
</style>
