<template>
	<div id="personal-info" class="center-items">
		<hr class="thicker-radius" />
		<h2>{{ languageStrings.removeRestrictions }}</h2>
		<p class="center-items">{{ languageStrings.fieldsMayBeRequired }}</p>
		<input
			id="address-street"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.addressStreet"
			v-model="addressStreet"
		/>
		<input
			id="address-number"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.addressNumber"
			v-model="addressNumber"
		/>
		<input
			id="address-suite"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.addressSuite"
			v-model="addressSuite"
		/>
		<input
			id="address-neighborhood"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.addressNeighborhood"
			v-model="addressNeighborhood"
		/>
		<input
			id="address-city"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.addressCity"
			v-model="addressCity"
		/>
		<input
			id="address-addressState"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.addressState"
			v-model="addressState"
		/>
		<input
			id="tax-id"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.taxId"
			v-model="taxId"
		/>
		<input
			id="national-id"
			class="extra-padding-text-fields"
			type="text"
			:placeholder="languageStrings.nationalId"
			v-model="nationalId"
		/>
		<button class="new-encore-button btn extra-padding" @click="setPersonalInfo()">
			{{ languageStrings.saveDetailedInfo }}
		</button>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import { onBeforeUnmount } from "vue";
import router from "@/router";

export default {
	name: "PersonalInfoBasicTransactions",
	props: {
		playerState: Object,
		userProfile: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			personalInfo: this?.userProfile?.personalInfo,
			emailAddress: this?.userProfile?.personalInfo?.emailAddress,
			taxId: this?.userProfile?.personalInfo?.taxId,
			nationalId: this?.userProfile?.personalInfo?.nationalId,
			addressNumber: this?.userProfile?.personalInfo?.addressNumber,
			addressStreet: this?.userProfile?.personalInfo?.addressStreet,
			addressSuite: this?.userProfile?.personalInfo?.addressSuite,
			addressNeighborhood: this?.userProfile?.personalInfo?.addressNeighborhood,
			addressCity: this?.userProfile?.personalInfo?.addressCity,
			addressState: this?.userProfile?.personalInfo?.addressState,
		};
	},
	watch: {
		userProfile() {
			this.personalInfo = this?.userProfile?.personalInfo;

			this.taxId= this.personalInfo?.taxId;
			this.nationalId= this.personalInfo?.nationalId;
			this.addressNumber= this.personalInfo?.addressNumber;
			this.addressStreet= this.personalInfo?.addressStreet;
			this.addressSuite= this.personalInfo?.addressSuite;
			this.addressNeighborhood= this.personalInfo?.addressNeighborhood;
			this.addressCity= this.personalInfo?.addressCity;
			this.addressState= this.personalInfo?.addressState;
			
		}
	},
	created() {
		// this.populateFields();
	},
	mounted() {},
	methods: {
		async setPersonalInfo() {
			this.serverBusy = true;
			this.busyText = "Updating Personal Info";

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				userId: this.playerState.userId,
				userProfile: {
					personalInfo: {
						personalInfo: this?.personalInfo,
						taxId: this?.taxId,
						nationalId: this?.nationalId,
						addressNumber: this?.addressNumber,
						addressStreet: this?.addressStreet,
						addressSuite: this?.addressSuite,
						addressNeighborhood: this?.addressNeighborhood,
						addressCity: this?.addressCity,
						addressState: this?.addressState,
					},
				},
				requiredValidations: "Minimal, BasicTransactions, ConspicuousTransactions",
			};

			let requestUrl = new URL("/api/v1/user/profile", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (dataJson?.status === "FailedPersonalInfoValidation") {
					dataJson.validationErrors.forEach((err) => {
						this.status.ok = false;

						if (this.countryCode == "MX") {
							if (err.field == "TaxId") {
								this.status.message = `${this.languageStrings.taxId}:`;
								this.status.message += this.handleErrorReason(err.reason);
							}
							if (err.field == "NationalId") {
								this.status.message = `${this.languageStrings.nationalId}:`;
								this.status.message += this.handleErrorReason(err.reason);
							}
							if (err.field == "AddressNumber") {
								this.status.message = `${this.languageStrings.addressNumber}:`;
								this.status.message += this.handleErrorReason(err.reason);
							}
							if (err.field == "AddressSuite") {
								this.status.message = `${this.languageStrings.addressSuite}:`;
								this.status.message += this.handleErrorReason(err.reason);
							}
							if (err.field == "AddressStreet") {
								this.status.message = `${this.languageStrings.addressNeighborhood}:`;
								this.status.message += this.handleErrorReason(err.reason);
							}
							if (err.field == "AddressCity") {
								this.status.message = `${this.languageStrings.addressCity}:`;
								this.status.message += this.handleErrorReason(err.reason);
							}
							if (err.field == "AddressState") {
								this.status.message = `${this.languageStrings.addressState}:`;
								this.status.message += this.handleErrorReason(err.reason);
							}
						} else {
							this.status.message = `${this.camelToTitleCase(err.field)}: ${this.camelToTitleCase(
								err.reason
							)}`;
						}

						this.eventBus.emit("updateStatus", this.status);
					});
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				if (dataJson?.status !== "Success") {
					this.status.ok = false;
					this.status.message = dataJson.status;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				let newPlayerState = this.playerState;

				newPlayerState.userProfile = dataJson.userProfile;
				this.personalInfo = dataJson?.userProfile?.personalInfo;

				this.status.ok = true;
				this.status.message = this.languageErrorStrings.profileUpdated;
				this.eventBus.emit("updatePlayerState", newPlayerState);
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateUserProfile");
				// this.populateFields();
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
		handleErrorReason(reason) {
			if (reason == "MayNotBeBlank") {
				return this.languageErrorStrings.mayNotBeBlank;
			}
			if (reason == "MustBeTwoLetters") {
				return this.languageErrorStrings.mustBeTwoLetters;
			}
			if (reason == "InvalidEmailAddress") {
				return this.languageErrorStrings.invalidEmailAddress;
			}
			if (reason == "ValueOutOfRange") {
				return this.languageErrorStrings.valueOutOfRange;
			}
			if (reason == "ContainsInvalidCharacters") {
				return this.languageErrorStrings.containsInvalidCharacters;
			}
		},
	},
	// populateFields() {
	// 	this.nameFirst = this?.personalInfo?.nameFirst;
	// 	this.nameLast = this?.personalInfo?.nameLast;
	// 	this.nameLast2 = this?.personalInfo?.nameLast2;
	// 	this.birthDate = new Date(this?.personalInfo?.birthDate).toISOString().split("T")[0];
	// 	this.nationality = this?.personalInfo?.nationality;
	// 	this.emailAddress = this?.personalInfo?.emailAddress;
	// 	this.emailVerificationStatus = this?.personalInfo?.emailVerificationStatus;
	// 	this.addressCountry = this?.personalInfo?.addressCountry;
	// 	this.addressCounty = this?.personalInfo?.addressCounty;
	// 	this.addressZipCode = this?.personalInfo?.addressZipCode;
	// },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#personal-info {
	display: flex;
	flex-flow: column;
}

.thicker-radius {
	width: 100%;
}

input {
	width: 50%;
	border-radius: 15px;
	height: 25px;
	color: #6305c9;
}
@media (max-width: 768px) {
	input {
		width: 75% !important;
	}
}
</style>
