<template>
	<div :id="mainNavIsHidden ? 'main-nav-hidden' : 'cash-out'">
		<h1>{{ languageStrings.webPayCashOut }}</h1>
		<div v-if="countryCode !== 'MX' && casinoList.length > 1">
			<h2>{{ languageStrings.selectCasino }}</h2>
			<DropdownMenu id="casino-select" :casinoList="casinoList" :languageStrings="languageStrings" />
		</div>

		<div id="select-container">
					<h3 style="color: black;">{{ languageStrings.selectProvider }}</h3>
					<select
						:title="languageStrings.selectProvider"
						class="curved-border encore-color select-width"
						v-model="provider"
						id="paymentOptions"
					>
						<option v-for="(item, index) in paymentOptions" :key="index" :value="item">
							{{ item }}
						</option>
					</select>
				</div>
		<div v-if="selectedCasino?.id" class="cash-out-container">
			<div class="content-container">
				<!-- <div id="select-container">
					<h3>{{ languageStrings.selectProvider }}</h3>
					<select
						:title="languageStrings.selectProvider"
						class="curved-border encore-color select-width"
						v-model="provider"
						id="paymentOptions"
					>
						<option v-for="(item, index) in paymentOptions" :key="index" :value="item">
							{{ item }}
						</option>
					</select>
				</div> -->
				<div id="nuvei-component">
					<NuveiCashOut
						v-if="webPayOptions?.nuvei && provider === 'nuvei'"
						:playerState="playerState"
						:webPayOptions="webPayOptions"
						:systemCurrencyTool="systemCurrencyTool"
						:languageErrorStrings="languageErrorStrings"
						:languageStrings="languageStrings"
						:provider="provider"
						:selectedCasino="selectedCasino"
					/>
				</div>
				<div id="conekta-component">
					<ConektaCashOut
						v-if="webPayOptions?.conekta && provider === 'conekta'"
						:playerState="playerState"
						:webPayOptions="webPayOptions"
						:systemCurrencyTool="systemCurrencyTool"
						:languageErrorStrings="languageErrorStrings"
						:languageStrings="languageStrings"
						:provider="provider"
						:selectedCasino="selectedCasino"
					/>
				</div>
				<div id="directa-component">
					<DirectaCashOut
						v-if="webPayOptions?.directa && provider === 'directa'"
						:playerState="playerState"
						:webPayOptions="webPayOptions"
						:systemCurrencyTool="systemCurrencyTool"
						:languageErrorStrings="languageErrorStrings"
						:languageStrings="languageStrings"
						:provider="provider"
						:selectedCasino="selectedCasino"
					/>
				</div>
			</div>
		</div>
	</div>
	<!-- </div> -->
</template>

<script>
import { onBeforeUnmount } from "vue";
import CurrencyTool from "@/dependencies/currencyTool";
import sharedScripts from "@/dependencies/sharedScripts";
import DropdownMenu from "@/components/DropdownMenu";
import NuveiCashOut from "@/components/NuveiCashOut";
import ConektaCashOut from "@/components/ConektaCashOut";
import DirectaCashOut from "@/components/DirectaCashOut";

export default {
	name: "WebPayCashOut",
	inheritAttrs: false,
	props: {
		casinoList: Array,
		playerState: Object,
		isMobile: Boolean,
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		systemSettings: Object,
		mainNavIsHidden: Boolean,
	},
	components: {
		DropdownMenu,
		NuveiCashOut,
		ConektaCashOut,
		DirectaCashOut,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			cashOutCurrency: {},
			inGameCurrencyInfo: {},
			nuveiResult: {},
			nuveiDialog: null,
			selectedCasino: null,
			casinoId: null,
			nuveiShow: false,
			rabbitsfootMessage: null,
			webPayOptions: {},
			toPlayerCashCOC: null,
			paymentType: null,
			allowCashOutLATAM: false,
			allowCashOutSTPmex: false,
			safeChargeApiResult: null,
			buttonText: "",
			transactionStatus: null,
			transactions: null,
			checkoutStatus: "",
			paymentOptions: [],
			provider: "nuvei",
		};
	},
	watch: {
		casinoId() {
			if (this.casinoId) this.getWebPayOptions();
		},
	},
	created() {
		this.eventBus.on("casinoSelected", (payload) => {
			this.selectedCasino = payload;
			this.casinoId = payload.id;
			this.inGameCurrencyInfo = new CurrencyTool(payload.inGameCurrencyInfo, this.localeString);
		});
		onBeforeUnmount(() => {
			this.eventBus.off("casinoSelected");
		});
		if (this.casinoId) this.getWebPayOptions();
	},
	mounted() {
		if (this.countryCode === "MX" || this.casinoList.length === 1)
			this.eventBus.emit("casinoSelected", this.pickDefaultCasino());
	},
	methods: {
		getPaymentOptions() {
			let webPayKeys = Object.keys(this.webPayOptions);
			webPayKeys.forEach((key) => {
				if (
					(key === "nuvei" || key === "conekta" || key === "directa") &&
					(this.webPayOptions[key]?.allowCashOut ||
						this.webPayOptions[key]?.allowCashOutLATAM ||
						this.webPayOptions[key]?.allowCashOutSTPmex)
				)
					this.paymentOptions.push(key);
			});
		},
		pickDefaultCasino() {
			let casinoIdSelected;
			casinoIdSelected = this.casinoList.filter((casino) => casino.isEnabled === true)[0];
			return casinoIdSelected;
		},
		async getWebPayOptions() {
			this.busyText = this.languageStrings.loadingBuyIns;
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL(`/api/v1/webpay/options`, this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.playerState.casinoId) params.set("casinoId", this.playerState.casinoId);

			params.set("casinoId", this.casinoId);

			requestUrl.search = params.toString();

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.webPayOptions = dataJson;
				this.availableOffers = dataJson.availableOffers;
				this.inGameCurrencyInfo = dataJson.casino.currencyInfo;

				if (this.webPayOptions?.nuvei?.allowCashOutLATAM)
					this.allowCashOutLATAM = this.webPayOptions.nuvei.allowCashOutLATAM;
				if (this.webPayOptions?.nuvei?.allowCashOutSTPmex)
					this.allowCashOutSTPmex = this.webPayOptions.nuvei.allowCashOutSTPmex;

				let _state = this.playerState;
				_state.restrictedNeedsMoreInfo =
					dataJson?.taxAmlRestrictions?.idCheckNeeded || dataJson?.taxAmlRestrictions?.morePersonalInfoNeeded;
				this.eventBus.emit("updatePlayerState", _state);

				this.getPaymentOptions();

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	text-align: center;
}

#cash-out {
	display: grid;
	width: 95%;
	margin: auto;
}

#cash-out {
	/* ****** temp fix for a CSS bug I need to understand ****** */
	top: 115px;
	/* left: 20px; */
}

.input-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 95%;
	margin-top: 2vh;
	margin-left: auto;
	margin-right: auto;
}

.input-section > span {
	display: flex;
	align-items: center;
	margin-left: 10px;
}

#nuvei-container {
	display: none;
}

#nuvei-container.show {
	display: grid;
	align-items: center;
	position: absolute;
	top: 0;
	margin: auto;
	height: 100%;
	width: 100%;
	background-color: #000;
}

#nuvei-dialog {
	width: 80%;
	margin: auto;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
	box-sizing: border-box;
	text-align: center;
}

::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

#btn-container a {
	text-decoration: none;
}

#btn-container * {
	margin: 15px;
}

select#offer-payment-type {
	width: 100%;
	height: 5vh;
	text-align: center;
	margin-left: 6vw;
	color: #5d3fd3;
	font-weight: bolder;
}

#payment-type-section {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 19px;
	/* margin-left: 1vw; */
	width: 75%;
}

#balances {
	background: white;
	display: flex;
	align-items: center;
	color: #5d3fd3;
	border-radius: 25px;
	width: 75%;
	margin-right: auto;
	margin-left: auto;
	font-size: 17px;
	font-weight: bolder;
	height: 5vh;
	margin-top: 3vh;
}

#toPlayerCashCOC {
	border-radius: 25px;
	width: 100%;
	height: 4.6vh;
	color: #5d3fd3;
	text-align: center;
	margin-right: 3vw;
}

.button-container {
	background: white;
	border-radius: 15px;
	width: 11vw;
	margin: 15px auto;
}

.buy-in-btn {
	-webkit-text-fill-color: transparent;
	background-clip: text;
	margin: auto;
	width: 11vw;
	height: 4.5vh;
	font-weight: bolder;
	overflow-wrap: break-word;
}

.button-container:hover {
	width: 22.5vw;
	padding-left: 6px;
}

.current-balance {
	margin: 4px auto;
}

iframe {
	margin: auto;
	width: 90;
	height: 400px;
	border-radius: 12px;
}

.cash-out-container {
	background-image: url("../../public/styleOverrides/encore_online_pixel_bg.png");
	width: 60%;
	border-radius: 12px;
	margin: auto;
	padding: 15px;
}

#select-container {
	margin: 15px auto;
	/* display: grid; */
	align-items: center;
	width: 230px;
	/* height: 100%; */
	justify-content: center;
	text-align: center;
}

#select-container > * {
	padding: 5px 15px;
}

#align-min-max {
	margin-top: 6px;
}
.expand-input-mobile {
	width: 40%;
}

#main-nav-hidden {
	width: 100%;
	margin-left: 0;
	background-color: white;
	height: 800px;
	margin-top: 116px;
}

h1 {
	color: black;
}

.curved-border {
	font-weight: bold;
}

@media (min-width: 768px) {
	#cash-out {
		width: 80%;
		margin-left: 20%;
		position: absolute;
	}

	#nuvei-dialog {
		width: 50%;
	}
	iframe {
		margin: auto;
		width: 50%;
	}
}

@media (min-width: 1629px) {
	.playerCashCOC {
		margin-left: 5.5vw;
	}
}

@media (max-width: 767px) {
	.cash-out-container {
		width: 90%;
		border-radius: 12px;
		margin-left: auto;
		margin-right: auto;
		/* height: 390px; */
	}

	.button-container {
		background: white;
		border-radius: 15px;
		width: 35vw;
		margin-left: auto;
		margin-right: auto;
		margin-top: 7.5vh;
	}

	.button-container:hover {
		width: 36vw;
	}

	.buy-in-btn {
		background: linear-gradient(to right, blue, purple, #fa5f55);
		-webkit-text-fill-color: transparent;
		background-clip: text;
		margin: auto;
		border-radius: 15px !important;
		width: 35vw;
		height: 4.5vh;
		font-weight: bolder;
		overflow-wrap: break-word;
	}

	.content-container {
		display: flex;
		flex-direction: column;
	}

	#balances {
		width: 90%;
	}

	#toPlayerCashCOC {
		border-radius: 25px;
		width: 100%;
		height: 4.6vh;
		color: #5d3fd3;
		text-align: center;
		margin-right: 0;
	}

	#payment-type-section {
		width: 90%;
		margin-left: auto;
		margin-right: 7vw;
	}

	#payment-type-label {
		margin-left: 9vw;
	}

	select#offer-payment-type {
		width: 65%;
		height: 5vh;
		text-align: center;
		margin-left: 1vw;
		color: #5d3fd3;
		font-weight: bolder;
	}

	#select-container {
		/* display: grid; */
		width: 230px;
		justify-items: center;
	}

	.select-width {
		width: 130px;
	}

	#cash-out {
		margin-top: 96px;
	}	
}
</style>
