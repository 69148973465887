<template>
	<div class="align-items-center">
		<div v-if="serverBusy" class="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<div v-if="userProfile.emailVerificationStatus === 'Verified' || notLoggedinVerifierd">
			<h1>{{ languageStrings.emailVerified }}</h1>
			<!-- <button v-if="!playerState?.loggedIn" @click="logonFromHome()" class="btn center">Log In</button> -->
		</div>
		<div v-else>
			<h1>{{ languageStrings.emailAddressVerification }}</h1>
			<div class="input-container center">
				<label for="verify">{{ languageStrings.code }}</label>
				<input
					type="text"
					id="verify"
					name="verify"
					@keydown.enter="verifyEmailCode(codeInput)"
					v-model="codeInput"
					placeholder="Enter Code Here"
				/>
			</div>
			<button v-if="codeInput.length === 6" class="btn center" @click="verifyEmailCode(codeInput)">{{ languageStrings.verifyEmail }}</button>
			<p v-if="codeInput.length > 6" class="warn center-text">{{ languageStrings.codeShouldNotBeMoreThanSixCharacters }}</p>
		</div>
		<div class="resnd-code-div">
			<button
					class="new-encore-button btn"
					type="submit"
					:title="languageStrings.sendVerificationEmail"
					@click="sendVerificationEmail()"
				>
					{{ languageStrings.resendVerificationCode }}
				</button>
		</div>
	</div>
</template>

<script>
import router from "@/router";
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "VerifyEmail",
	props: {
		verificationCode: String,
		playerState: Object,
		userProfile: Object,
		systemSettings: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			codeInput: "",
			notLoggedinVerifierd: false,
		};
	},
	watch: {
		systemSettings: {
			handler() {
				if (!this?.systemSettings?.features?.allowEmailVerification) router.push("/");
			},
			deep: true,
		},
	},
	created() {
		if (this.verificationCode) {
			this.verifyEmailCode();
			this.eventBus.emit("updateUserProfile");
		}
	},
	mounted() {},
	methods: {
		logonFromHome() {
			router.push("/");
		},
		async verifyEmailCode(manualEntry = null) {
			this.serverBusy = true;
			this.busyText = this.languageStrings.verifyEmailAddress;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				code: manualEntry ? manualEntry : this.verificationCode,
			};

			let requestUrl = new URL("/api/v1/user/verification/email", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok) this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (dataJson?.status !== "Success") {
					this.notLoggedinVerifierd = this.status.message === "Already verified" ? true : false;
					this.status.ok = this.notLoggedinVerifierd;
					this.status.message = this.status.message ? this.status.message : this.languageErrorStrings.verifyEmailUnsuccessful;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				this.status.ok = true;
				this.status.message = this.languageErrorStrings.emailVerifiedSuccessfully;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateUserProfile");
				this.notLoggedinVerifierd = body.code ? true : false;
				this.serverBusy = false;
				this.busyText = "";
				router.push("/WebPayCashIn");
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
		async sendVerificationEmail() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.sendingVerificationEmail;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let requestUrl = new URL("/api/v1/user/verification/email/code", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (dataJson?.status !== "Success") {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.sendVerificationEmailUnsuccessful;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				this.status.ok = true;
				this.status.message = this.languageErrorStrings.verificationEmailsuccessfull;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateUserProfile");
				this.emailAddress = this.userProfile?.personalInfo.emailAddress;
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading {
	display: grid;
	align-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	text-align: center;
	font-weight: bold;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	z-index: 100;
}

.loading-message {
	text-align: center;
	display: block;
	margin: 15px;
}

.center {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 15px auto;
}

input {
	font-family: monospace;
	font-size: 1.25em;
}

.resnd-code-div {
	display: flex;
	position: absolute;
	bottom: 4%;
}

.align-items-center {
	display: flex;
	justify-content: center;
}
</style>
